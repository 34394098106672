import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


const sentryConf = {
  dsn: "https://4d0980a2cd064a90bd778ad3afe92be0@o1334976.ingest.sentry.io/6604737",
  tunnel: "./sentry.php",
  debug: false,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: environment.envName,
  tracePropagationTargets: [
    "localhost",
    "https://weiterbildung.developerakademie.com",
    "https://cdn.developerakademie.com"
  ],
};

Sentry.init(sentryConf);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
