import { InitForm } from "custom-form-lib";
import { ApplyConfig } from "src/app/apply/services/apply.service";
import { sections } from "src/app/forms-sections/devsecops-sections";

const applyConfig: ApplyConfig = {
  endPoint: './postTunnel.php',
};
const applyDevSecOpsConfig : InitForm = {
    sections: sections,
    testing: false,
    applyFullForm: false,
    currentSection: 0,
    endPoint: 'https://register-form.developerakademie.org/leads/',
    estimatedMinutes: 5,
}
export const environment = {
  production: true,
  envName: 'staging',
  applyConfig,
  applyDevSecOpsConfig
};
