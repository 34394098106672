import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { InitialSource } from 'src/app/services/trackingservice.service';

export interface PayloadInterface {
  session: number,
  form: any,
  initialSource?: InitialSource,
  utm_source?: string,
  utm_medium?: string,
  utm_campaign?: string,
  utm_id?: string,
  utm_term?: string,
  utm_content?: string
}

@Injectable({
  providedIn: 'any'
})
export class FetchingService {

  response = {
    error: false,
    message: "" as SafeHtml
  };

  showResponseFeedback(message: SafeHtml) {
    this.response.error = true;
    this.response.message = message;
  }

  closeResponseFeedback() {
    this.response.error = false;
    this.response.message = "";
  }

  fetching = false;
  errors: any[] = [];

  onResponseNotOKCounter = 0;
  readonly MAXIMAL_TRY_COUNTER = 3;

  postingConfig = {
    endPoint: '',
    settings: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: ''
    }
  }

  /**
   * Sends a payload to script app
   */
  async post(payload: PayloadInterface): Promise<any> {
    // return Promise.resolve("Test");
    try {

      this.postingConfig.settings.body = JSON.stringify({ data: payload });
      const response = await fetch(
        this.postingConfig.endPoint,
        this.postingConfig.settings as unknown as RequestInit
      );

      this.fetching = false;
      if (!response.ok) {
        if (response.status >= 500) {
          // const error = await response.text();
          throw "Leider kann derzeit das Formular nicht ausgefüllt werden!\nProbieren Sie bitte zu eine spätere Zeitpunkt nocheinmal.";
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType?.indexOf("application/json") !== -1) {
          const error = await response.json();
          throw error.detail;
        }
      }
      return response.json();
    } catch (error: any) {
      if (error instanceof Error)
        throw { code: 'fetch', type: 'error', message: error.message };
      else
        throw { code: 'fetch', type: 'error', message: error }
    }
  }

  async handleFetch(response: Response): Promise<any> {

  }
}
