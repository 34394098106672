import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { requiredAtLeastOneSelection } from "./formcustomvalidators";

/**
 * Temporally canApply
 */
const headerForm = new UntypedFormGroup({
    accept: new UntypedFormControl(false, Validators.requiredTrue)
});

export const NO_WHITE_SPACE_REGEX = /^(\s+\S+\s*)*(?!\s).*$/;
const introductionForm = new UntypedFormGroup({
    // gender: [null, [Validators.required]],
    lastName: new UntypedFormControl(null, Validators.required),
    firstName: new UntypedFormControl(null, Validators.required),
}, { validators: [Validators.required, Validators.pattern(NO_WHITE_SPACE_REGEX)] });

const ageForm = new UntypedFormGroup({
    age: new UntypedFormControl(null, Validators.required),
});

const professionForm = new UntypedFormGroup({
    profession: new UntypedFormControl(null, Validators.required)
});

const experienceForm = new UntypedFormGroup({
    experience: new UntypedFormControl(null, Validators.required)
});

const desiresForm = new UntypedFormGroup({
    desires: new UntypedFormArray([
        new UntypedFormControl(null),
        new UntypedFormControl(null),
        new UntypedFormControl(null),
        new UntypedFormControl(null),
        new UntypedFormControl(null),
    ], requiredAtLeastOneSelection as ValidatorFn),
});

const stacksForm = new UntypedFormGroup({
    stacks: new UntypedFormArray([
        new UntypedFormControl(null),
        new UntypedFormControl(null),
        new UntypedFormControl(null)
    ], requiredAtLeastOneSelection as ValidatorFn),
});

const willForm = new UntypedFormGroup({
    will: new UntypedFormControl(null, Validators.required)

});

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailForm = new UntypedFormGroup({
    email: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.pattern(EMAIL_REGEX)])),
});

const phoneNumberForm = new UntypedFormGroup({
    phoneNumber: new UntypedFormControl('+49', Validators.compose(
        [
            Validators.required,
            Validators.pattern("^\\+?([\\s\\-\\/]?\\(?[\\d]{1,16}\\)?){1,5}\\s?$"),
            Validators.minLength(5),
        ])),
});

const reachableForm = new UntypedFormGroup({
    reachable: new UntypedFormControl(null, Validators.required)
});

const currentSituationForm = new UntypedFormGroup({
    situation: new UntypedFormControl(null, Validators.required)
});

const acceptForm = new UntypedFormGroup({
    accept: new UntypedFormControl(null, Validators.requiredTrue)
});

export const applyReactForm = new UntypedFormGroup(
    {
        headerForm, // BooleanInputControl,
        introductionForm, // TextInput
        ageForm, // SingleChoiceControl
        professionForm, // SingleChoiceControl
        experienceForm, // SingleChoiceControl
        desiresForm, // MultipleChoiceControl
        stacksForm, // MultipleChoiceLastSelectsAll
        willForm, // TextAreaInputControl
        emailForm, // EmailInputControl
        phoneNumberForm, // PhoneInputControl
        reachableForm, // SingleChoiceControl
        // currentSituationForm, // SingleChoiceControl
        // acceptForm // BooleanChoiceControl
    }
);

function logAllControls() {
    for (const key in applyReactForm.controls) {
        if (Object.prototype.hasOwnProperty.call(applyReactForm.controls, key)) {
            const element = applyReactForm.controls[key];
        }
    }
}