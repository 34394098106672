import { Section } from "custom-form-lib"

const startDevSecOps = {
    contentId: "Start",
    onInvalidMessage: "Starten",
    onValidMessage: "Starten",
    formGroupType: "BooleanInputControl",
    options: [`
      <div class="1500:ml-[30px] ml-3
      text-[14px] 600:text-[14px] 1200:text-[16px] 1500:text-[22px] dark:text-[#B6C4C9]">
        Bitte stimme kurz der
        <b class="dark:text-white">
          <a rel="noopener noreferrer" target="_blank"
            onclick="event.stopPropagation();"
            href="https://developerakademie.com/data-protection">
              Datenschutzerklärung
          </a>
        </b> zu.
      </div>`
    ], //title per option
    required: true,
    formControlNames: ["accept"], //one formControlName per option
    // title: `Starte deine Karriere als <span class="text-orange">DevSecOps</span>`
} as Section;

const introduction = {
    contentId: "Introduction",
    formGroupType: "TextInputControl",
    options: ["Dein Vorname", "Dein Nachname"],
    formControlNames: ["firstName", "lastName"],
    required: true,
    title: `
    <p class="text-[22px] font-bold font-figtree mb-8
      900:text-[28px]">
      <span class="text-orange">
        Schön, dass Du hier bist.
      </span> Bitte stelle dich kurz vor.
    </p>`
} as Section;

const age = {
    formGroupType: "SingleChoiceControl",
    options: ["18-25", "26-35", "36-45", "46+"],
    formControlNames: ["age"],
    required: true,
    title: `Wie alt bist du?`
} as Section;

const profession = {
    formGroupType: "SingleChoiceControl",
    options: [
        "Schüler / Schülerin",
        "Student / Studentin",
        "Angestellter / Angestellte",
        "Selbständig",
        "Arbeitsuchend",
        "Sonstiges"
    ],
    formControlNames: ["profession"],
    required: true,
    title: `Dein aktueller Berufsstand`
} as Section;

const experience = {
    formGroupType: "SingleChoiceControl",
    formControlNames: ["experience"],
    options: [
        "Ich habe keine Programmiererfahrung.",
        "Ich habe Grundkenntnisse in mindestens einer Programmiersprache.",
        "Ich programmiere regelmäßig, besonders in Python.",
        "Ich bin erfahrener Entwickler mit Kenntnissen in mehreren Sprachen."
    ],
    title: "Welche Programmiererfahrung bringst du mit?",
    required: true
} as Section;

const desires = {
    formGroupType: "MultipleChoiceControl",
    formControlNames: ["desires"],
    options: [
        "Die Integration von Sicherheit in den Entwicklungsprozess.",
        "Die Automatisierung von Entwicklungs- und Bereitstellungsprozessen.",
        "Die Arbeit mit Cloud-Technologien und Containern.",
        "Die Optimierung der Zusammenarbeit zwischen Entwicklung und IT-Betrieb"
    ],
    title: "Was interessiert dich am meisten an DevSecOps?",
    required: true
} as Section;

const devSecOpsExperience = {
    formGroupType: "MultipleChoiceControl",
    formControlNames: ["devSecOpsExperience"],
    options: [
        "Ich habe bereits Erfahrung mit DevOps Tools wie Github Actions, Jenkins CI, GitLab CI",
        "Ich verfüge über Erfahrung mit CI/CD und Container-Technologien.",
        "Ich habe bereits mit Cloudtechnologien gearbeitet"
    ],
    title: "Welche der folgenden Aussagen beschreiben deine Erfahrungen  im Bereich DevSecOps?",
    required: true
} as Section;

const will = {
    onInvalidMessage: "Bitte ausfüllen",
    formGroupType: "TextAreaInputControl",
    formControlNames: ["will"],
    options: [
        "Gib hier deine Antwort ein..."
    ],
    required: true,
    title: "In einem Satz:<br>Warum willst Du Dich zum DevSecOps Spezialisten / Cloud Engineer  weiterbilden?"
} as Section;

const email = {
    contentId: "EmailAddress",
    onInvalidMessage: "Bitte ausfüllen",
    formGroupType: 'EmailInputControl',
    formControlNames: ['email'],
    options: [
        "Deine E-mail Adresse"
    ],
    title: 'Deine E-Mail für die Terminbestätigung',
    required: true,
} as Section;

const phoneNumber = {
    formGroupType: "PhoneInputControl",
    formControlNames: ["phoneNumber"],
    options: ["012345678901"],
    preselectedValues: ["+49"],
    title: "Unter welcher Telefonnummer können wir Dich für das Beratungsgespräch erreichen?",
    required: true
} as Section;

const reachableSection = {
    formGroupType: "SingleChoiceControl",
    formControlNames: ["reachable"],
    options: [
        "Morgens, zwischen 8-12 Uhr",
        "Nachmittags, zwischen 13-17 Uhr",
        "Abends, zwischen 17-20 Uhr"
    ],
    title: "Wann bist Du in der Regel am besten (telefonisch) erreichbar?",
    required: true
} as Section;

export const sections = [
    startDevSecOps,
    introduction,
    age,
    profession,
    experience,
    desires,
    devSecOpsExperience,
    will,
    email,
    phoneNumber,
    reachableSection
];
