import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplyComponent } from './apply.component';
import { ApplyBtnWrapperComponent } from './components/apply-btn-wrapper/apply-btn-wrapper.component';
import { ApplyFormNavComponent } from './components/apply-form-nav/apply-form-nav.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { EventsHandlerDirective } from './directives/events-handler.directive';

import { ApplyFormHeaderComponent } from './components/apply-form-header/apply-form-header.component';
import { ApplyFormTitleComponent } from './components/apply-form-title/apply-form-title.component';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular";
import { ApplyCountryFlagComponent } from './components/apply-country-flag/apply-country-flag.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { FetchingService } from './services/fetching.service';
import { ApplyConfig, ApplyService } from './services/apply.service';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({ declarations: [
        ApplyComponent,
        ApplyBtnWrapperComponent,
        ApplyFormNavComponent,
        EventsHandlerDirective,
        ApplyFormHeaderComponent,
        ApplyFormTitleComponent,
        ApplyCountryFlagComponent,
        AutoFocusDirective,
        FooterComponent
    ],
    exports: [
        FooterComponent
    ], imports: [CommonModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule], providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
            useValue: undefined
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class ApplyModule {
  constructor(trace: Sentry.TraceService) { }

  static forRoot(configuration: ApplyConfig): ModuleWithProviders<ApplyModule> {
    return {
      ngModule: ApplyModule,
      providers: [ApplyService, { provide: 'config', useValue: configuration }]
    };
  }
}
