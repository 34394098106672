<a class="hide-mobile" href="https://developerakademie.com/"><img src="assets/apply_form/Logo.svg" class="logo"></a>
<div [ngClass]="darkmode ? 'dark' : 'light'">
    <div class="main-content-desktop">
        <div class="thumbnail-container" (click)="showVideo = true">
            <img class="junus-img"
                srcset="assets/img/Junus@3x.webp 3x,assets/img/Junus@2x.webp 2x,assets/img/Junus@1x.webp 1x"
                src="assets/images/Junus@1x.webp" alt="">
            <div class="da-video-play-btn">
                <svg class="da-play-btn-bg" width="100" height="96" viewBox="0 0 100 96" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M50.6518 9.36871e-05C71.9925 0.0390544 92.6443 12.3527 98.6921 33.6527C104.349 53.5756 90.7987 72.0656 74.5326 84.1069C58.8915 95.6857 38.679 101.012 22.7443 89.8749C5.00192 77.4747 -4.48211 54.6413 2.08993 33.4763C8.69092 12.2181 29.1859 -0.0390956 50.6518 9.36871e-05Z" />
                </svg>

                <svg style="position: relative;" width="28" height="34" viewBox="0 0 28 34" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.94363 1.26734C2.94646 -0.00358963 0.333008 1.43105 0.333008 3.79832V30.2016C0.333008 32.5689 2.94646 34.0035 4.94363 32.7326L25.6891 19.531C27.5416 18.3521 27.5416 15.6478 25.6891 14.469L4.94363 1.26734Z"
                        fill="white" />
                </svg>
            </div>
        </div>

        <div class="card-thank-you bg-white dark:text-white dark:bg-[#0d3240]">
            <a href="https://developerakademie.com/" class="breadcrumb">
                < Home</a>
                    <div class="title-wrapper">
                        <h2 class="dark:text-white text-[#124658]">Bewerbung erhalten!<br> <span class="font-bold" style="color: #FD5B4F;">So geht's weiter</span></h2>
                        <svg width="77" height="84" viewBox="0 0 77 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect class="fill-[#BFE5F2] dark:fill-[#1e1e23]" opacity="0.5" y="19.625" width="64" height="64" rx="32"  />
                            <path class="fill-[#2796BF] dark:fill-[#fd5b4f]" fill-rule="evenodd" clip-rule="evenodd"
                                d="M43.0868 42.4867C43.6396 42.9274 43.7304 43.7328 43.2897 44.2855L29.804 61.1998C29.5768 61.4848 29.2389 61.6594 28.875 61.6798C28.5111 61.7003 28.1558 61.5647 27.8981 61.307L21.0409 54.4498C20.541 53.9499 20.541 53.1395 21.0409 52.6396C21.5408 52.1397 22.3512 52.1397 22.8511 52.6396L28.6953 58.4838L41.288 42.6896C41.7287 42.1369 42.5341 42.046 43.0868 42.4867Z"
                                fill="#2796BF" />
                            <path
                                d="M51 16.375C58.1183 16.375 63.8889 9.21156 63.8889 0.375C63.8889 9.21156 69.6594 16.375 76.7778 16.375C69.6594 16.375 63.8889 23.5384 63.8889 32.375C63.8889 23.5384 58.1183 16.375 51 16.375Z"
                                fill="#FFCE80" />
                            <path
                                d="M38 9.5C41.0376 9.5 43.5 6.58985 43.5 3C43.5 6.58985 45.9624 9.5 49 9.5C45.9624 9.5 43.5 12.4101 43.5 16C43.5 12.4101 41.0376 9.5 38 9.5Z"
                                fill="#FFCE80" />
                        </svg>
                    </div>

                    <div *ngIf="checkExperienceForm(); else noExperience">
                        <p class="text-wrapper text-[#124658] dark:text-white">Wir freuen uns, dich kennenzulernen und melden uns
                            <strong>spätestens bis {{
                                checkData()
                                }}</strong> bei dir. Halte Ausschau nach unserem Anruf - erkennbar an der Nummer
                            beginnend mit: <span style="color: #FD5B4F;">+49
                                89 3803
                                ****</span>
                        </p>
                        <p>
                            Mach dich fit für unser Gespräch – mit folgender Coding-Challenge! <a class="challenge-link"
                                href="https://www.youtube.com/watch?v=YGlJsgdgHuw" target=”_blank”>Jetzt
                                loslegen</a><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                viewBox="0 0 14 14" fill="none">
                                <path
                                    d="M8.39803 1.41375C8.04656 1.06228 7.47671 1.06228 7.12524 1.41375C6.77377 1.76522 6.77377 2.33507 7.12524 2.68654L10.5389 6.10016H1.28867C0.791615 6.10016 0.388672 6.50311 0.388672 7.00016C0.388672 7.49722 0.791616 7.90016 1.28867 7.90016H10.5383L7.12524 11.3132C6.77377 11.6647 6.77377 12.2346 7.12524 12.586C7.47671 12.9375 8.04656 12.9375 8.39803 12.586L13.3419 7.64221C13.3559 7.62846 13.3694 7.61426 13.3825 7.59963C13.6988 7.24625 13.6873 6.703 13.3478 6.3635L8.39803 1.41375Z"
                                    fill="#FD5B4F" />
                            </svg>
                        </p>
                    </div>

                    <ng-template #noExperience>
                        <p class="text-wrapper text-[#124658] dark:text-white">Wir freuen uns, dich kennenzulernen und melden uns
                            <strong>spätestens bis {{
                                checkData()
                                }}</strong> bei dir.
                            <br><br>Halte Ausschau nach unserem Anruf - erkennbar an der Nummer beginnend mit: <span
                                style="color: #FD5B4F;">+49
                                89 3803
                                ****</span>
                        </p>
                    </ng-template>

                    <p class="text-conclusion-container text-[#124658] dark:text-white">Bis bald, {{ userName }}! </p>
        </div>
    </div>
</div>


<div class="main-content-mobile">
    <a href="https://developerakademie.com/" style="align-self: flex-start;"><img
            src="assets/apply_form/Logo-dark-font.svg"></a>
    <a href="https://developerakademie.com/" class="breadcrumb">
        < Home</a>


            <div class="flex-column">
                <div class="title-wrapper">
                    <h2>Bewerbung erhalten!<strong style="color: #FD5B4F;"><br>So geht’s weiter</strong></h2>
                </div>
                <div class="thumbnail-mobile-container">
                    <div class="thumbnail-container">
                        <img class="junus-img"
                            srcset="assets/img/Junus@3x.webp 3x,assets/img/Junus@2x.webp 2x,assets/img/Junus@1x.webp 1x"
                            src="assets/images/Junus@1x.webp" alt="">
                        <div class="da-video-play-btn" (click)="showVideo = true">
                            <svg class="da-play-btn-bg" width="100" height="96" viewBox="0 0 100 96" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M50.6518 9.36871e-05C71.9925 0.0390544 92.6443 12.3527 98.6921 33.6527C104.349 53.5756 90.7987 72.0656 74.5326 84.1069C58.8915 95.6857 38.679 101.012 22.7443 89.8749C5.00192 77.4747 -4.48211 54.6413 2.08993 33.4763C8.69092 12.2181 29.1859 -0.0390956 50.6518 9.36871e-05Z" />
                            </svg>

                            <svg style="position: relative;" width="28" height="34" viewBox="0 0 28 34" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.94363 1.26734C2.94646 -0.00358963 0.333008 1.43105 0.333008 3.79832V30.2016C0.333008 32.5689 2.94646 34.0035 4.94363 32.7326L25.6891 19.531C27.5416 18.3521 27.5416 15.6478 25.6891 14.469L4.94363 1.26734Z"
                                    fill="white" />
                            </svg>
                        </div>
                    </div>
                </div>


                <div class="text-mobile-container">

                    <p class="text-wrapper text-[#124658] dark:text-white" style="margin-bottom: 18px;">Wir freuen uns, dich kennenzulernen und
                        melden
                        uns <strong>spätestens bis {{
                            checkData()
                            }}</strong> bei dir.
                        <br><br>Halte Ausschau nach unserem Anruf - erkennbar an der Nummer beginnend mit: <span
                            style="color: #FD5B4F;">+49
                            89 3803
                            ****</span>
                    </p>

                    <p style="text-align: left;" class="text-conclusion-mobile-container text-[#124658] dark:text-white">Bis bald, {{ userName }}!
                    </p>
                </div>

            </div>
</div>


<app-footer [darkmode]=darkmode></app-footer>

<div class="video-application" *ngIf="showVideo" (click)="showVideo = false">
    <iframe width="640" height="360"
        src="https://fast.wistia.net/embed/iframe/kqi3j97dfr?embedType=async&amp;videoFoam=true&amp;videoWidth=640?autoplay=1&amp;wmode=transparent"
        allowtransparency="true" frameborder="0" scrolling="no" name="wistia_embed" allowfullscreen=""
        mozallowfullscreen="" webkitallowfullscreen="" oallowfullscreen="" msallowfullscreen="" wmode="opaque"></iframe>
</div>