import { AbstractControl, AbstractControlDirective, UntypedFormArray, UntypedFormControl, ValidationErrors, ValidatorFn } from "@angular/forms";

// FormGroup Validator required at least one control to have a non null value
export function requiredAtLeastOneSelection(formArray: UntypedFormArray):  ValidationErrors | null {

        const noSelection = (controls : AbstractControl[]) => {
            return !controls.find((control : AbstractControl)  => control.value !== null);
        };
        return (noSelection(formArray.controls) ? { noSelection: true } : null);
}

// FormControl Validator required all other controls from same parent FormGroup to have a non null value
export function requiredAllSelected(controlToBeValidated: UntypedFormControl): ValidationErrors | null {

        const notAllSelected = (parentControl: UntypedFormArray) => {
            return parentControl.controls.find(c => c != controlToBeValidated && c.value == null);
        };
        return notAllSelected(controlToBeValidated.parent as UntypedFormArray) ? { notAllSelection: true } : null;

}