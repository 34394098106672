import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplyService } from '../../../apply/services/apply.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input()darkmode: boolean = false;
  year = new Date().getFullYear();

  constructor(private router: Router, public applyService: ApplyService,) { }

  ngOnInit(): void {
  }

  isTanksPage(): boolean {
    return this.router.url === '/bewerbung-eingegangen';
  }

  checkExperienceForm() {
    return this.applyService.applyForm.controls.experienceForm.value.experience.includes('noch nie eine Zeile');
  }

}
