import { Component, inject, OnInit } from '@angular/core';
import { CustomFormLibService, ExtraPayload, InitForm } from 'custom-form-lib';
import { Router } from '@angular/router';
import { TrackingserviceService } from '../services/trackingservice.service';
import { ThankYouPageEventTrackingService } from '../services/thank-you-page-event-tracking.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-dev-sec-ops-apply',
    templateUrl: './dev-sec-ops-apply.component.html',
    styleUrls: ['./dev-sec-ops-apply.component.scss']
})
export class DevSecOpsApplyComponent implements OnInit {

    customForm = inject(CustomFormLibService);
    router = inject(Router);
    trackingService = inject(TrackingserviceService);
    eventTrackingService = inject(ThankYouPageEventTrackingService);

    ngOnInit(): void {
        this.initApplyForm();
        this.trackFirstName();
        this.checkForDarkMode();
    }

    private initApplyForm(){
        this.customForm.setApplyForm(this.defineApplyFormConfiguration());
    }

    private defineApplyFormConfiguration(){
        const CONFIGURATION = environment.applyDevSecOpsConfig as InitForm;
        CONFIGURATION['extraPayloads'] = this.defineExtraPayload();
        return CONFIGURATION;
    }

    private defineExtraPayload(){
        const extraPayload = [
            { key: "source", value: this.trackingService.getInitialSource() },
            { key: "utm_source", value: localStorage.getItem('utm_source') || '', },
            { key: "utm_campaign", value: localStorage.getItem('utm_campaign') || '', },
            { key: "utm_id", value: localStorage.getItem('utm_id') || '', },
            { key: "utm_content", value: localStorage.getItem('utm_content') || '', },
            { key: "form", value: { stacks: ["DevSecOps"] } }
        ] as ExtraPayload[];
        return extraPayload;
    }

    private trackFirstName(){
        const YOUR_NAME_SECTION = this.customForm.applyForm.sections.find((section) => {
            return section.contentId === 'Introduction';
        });
        const YOUR_FIRST_NAME_CONTROL = YOUR_NAME_SECTION?.formGroup.get('firstName');
        YOUR_FIRST_NAME_CONTROL?.valueChanges.subscribe(firstName => this.eventTrackingService.setName(firstName));
    }

    /**
     * Track Email and Choose Stacks for Google Tag Manager Complete Registration
     * @see https://tagmanager.google.com/#/container/accounts/6004809043/containers/51594281/workspaces/84/tags
     * @param value
     */
    onLastPostSend(value: any) {
        const YOUR_EMAIL_SECTION = this.customForm.applyForm.sections.find((section) => {
            return section.contentId === 'EmailAddress';
        });
        const EMAIL = YOUR_EMAIL_SECTION?.formGroup.get("email")?.value;
        (document.getElementById("completeRegistrationEmail") as HTMLElement).innerText = EMAIL;
        (document.getElementById("completeRegistrationStacks") as HTMLElement).innerText = "DevSecOps";
        this.router.navigate(['bewerbung-eingegangen']);
    }

    checkForDarkMode() {
        const darkModeElement = document.querySelector('.dark');
        const bodyElement = document.querySelector('body');

        if (darkModeElement) {
            bodyElement?.classList.add('dark');
        }
    }


}
