<lib-custom-form-lib class="cfl-dark dark darkmode" (lastPostSended)="onLastPostSend($event)">
	<div side-content>
		
          <div class="flex flex-col items-center text-white">
          	  <img srcset="assets/apply_form/03_Mentor@2x.webp 2x, assets/apply_form/03_Mentor@3x.webp 3x"
	                src="assets/apply_form/03_Mentor@1x.webp" alt="">
	        
	        <div class="w-[110px] 900:w-[120px] 1400:w-[140px] mt-6 900:mt-3 trustpilot-widget da-trust-pilot" data-locale="de-DE" data-template-id="53aa8807dec7e10d38f59f32"
	            data-businessunit-id="5e9825574f392a0001029067" data-style-height="120px" data-style-width="100%"
	            data-theme="light" data-text-color="#050505">
	            <a id="da-trustpilot" href="https://de.trustpilot.com/review/developerakademie.com" target="_blank"
	                rel="noopener">Trustpilot</a>
	        </div>
	        <img class="w-[110px] 900:w-[120px] 1400:w-[140px] mt-6 900:mt-3TUV-certificate" src="assets\img\TR-Testmark_9000012503_DE_CMYK_without-QR-Code.png">
          </div>
        
	</div>

	<div class="max-width-80 ">
		<ng-template libCustomFormContentBtn="Start">
			<div class="flex items-center">
				<p class="text-[11px] text-gray-lighter max-w-full 900:max-w-[95px] ">Es folgen ein paar Fragen für
					unser
					Gespräch</p>
				<img class="hidden 900:block w-12" src="assets/apply_form/Arrow.svg">
			</div>
		</ng-template>

		<ng-template libCustomFormContent="Start">
			<div class="flex flex-col">

				<div class="hidden top-6p left-0 right-0 mx-auto h-1/3 w-fit min-h900-max-w:block">
					<img class="h-52 900:h-64"
						srcset="assets/apply_form/03_Mentor@2x.webp 2x, assets/apply_form/03_Mentor@3x.webp 3x"
						src="assets/apply_form/03_Mentor@1x.webp" alt="">
				</div>
				<div>
					<h1 class="text-[22px] font-extrabold font-figtree 
					mb-5 1200:mb-8 1500:mb-9
					mt-4 900:mt-0
                    400:text-[27px] 600:text-[30px] 1200:text-[50px] dark:text-white">
						Starte deine Karriere als <span class="text-orange">DevSecOps</span>
					</h1>
				</div>
				<div>
					<div class="flex flex-col gap-5 1500:gap-8 dark:text-white">
						<p class="text-[14px] 600:text-[18px] 1200:text-[20px] 1500:text-[26px] font-light">Mit unserem kostenlosen
							45-minütigen Beratungsgespräch:</p>
						<div class="flex flex-col gap-4 1500:gap-8">
							<div class="flex items-center gap-7 h-8 1500:h-[50px]">
								<img class="h-full" src="assets/apply_form/eignungscheck.svg" alt="">
								<span
									class="menu text-[14px] 600:text-[14px] 1200:text-[16px] 1500:text-[22px]  dark:text-[#B6C4C9]"><strong
										class="dark:text-white">Eignungs-Check: </strong>Ist eine Ausbildung zum
									IT-Spezialisten
									dein Ding?</span>
							</div>
							<div class="flex items-center gap-7 h-8 1500:h-[50px]">
								<img class="h-full" src="assets/apply_form/karriereplan.svg" alt="">
								<span
									class="menu text-[14px] 600:text-[14px] 1200:text-[16px] 1500:text-[22px] dark:text-[#B6C4C9]"><strong
										class="dark:text-white">Karriereplan: </strong>Bekomme deinen maßgeschneiderten
									Plan
									zum
									ersten IT-Job</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</lib-custom-form-lib>
<app-footer class="900:pl-[10%] 1570:pl-0 absolute 1570:bottom-0 left-0 1570:right-0 1570:mx-auto" [darkmode]=true></app-footer>