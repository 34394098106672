import { Component, Input, OnInit } from '@angular/core';
import { FacebookPixelEventTrackerService } from '../services/facebook-pixel-event-tracker.service';
import { ThankYouPageEventTrackingService } from '../services/thank-you-page-event-tracking.service';
import { ApplyService } from '../apply/services/apply.service'

@Component({
    selector: 'app-thank-you-page',
    templateUrl: './thank-you-page.component.html',
    styleUrls: ['./thank-you-page.component.scss']
})
export class ThankYouPageComponent implements OnInit {
    darkmode = false;
    userName: string = "";
    showVideo: boolean = false;

    constructor(
        private tracking: FacebookPixelEventTrackerService,
        private trackingThankYouPage: ThankYouPageEventTrackingService,
        private thankYouPageEventTrackingService: ThankYouPageEventTrackingService,
        public applyService: ApplyService,
    ) { }

    ngOnInit(): void {
        this.checkDarkMode()
        this.thankYouPageEventTrackingService.getName().subscribe(name => {
            this.userName = name;
        });
        // this.tracking.trackEvent('CompleteRegistration');
        // this.trackingThankYouPage.trackEvent();
    }

    checkExperienceForm() {
        return this.applyService.applyForm.controls.experienceForm.value.experience?.includes('noch nie eine Zeile');
    }

    getDate(date: Date, delayDays: number) {
        date.setDate(date.getDate() + delayDays);

        const monthName = date.toLocaleString('de-DE', { month: 'long' });
        const weekDay = date.toLocaleString('de-DE', { weekday: 'long' });
        return `${weekDay}, den ${date.getDate()}. ${monthName}`;
    }

    checkData() {
        const date: Date = new Date();
        const startweekDay = date.toLocaleString('de-DE', { weekday: 'long' });

        switch (startweekDay) {
            case 'Donnerstag':
                return this.getDate(date, 4)

            case 'Freitag':
                return this.getDate(date, 4)

            case 'Samstag':
                return this.getDate(date, 3)

            default:
                return this.getDate(date, 2)
        }
    }

    checkDarkMode(){
        if(document.querySelector('.dark')){
            this.darkmode =true
            document.body.classList.add('dark-bg');
        }else{
            this.darkmode =false
        }
    }

}
